import React, { useEffect, useState } from 'react'
import './App.css';
import MainRouter from './MainRouter';
import MainRouterTest from './MainRouterTest';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './theme/GlobalStyles';
import { lightTheme, darkTheme } from './theme/themes';
import useDarkMode from './components/useDarkMode';
import AppContext from './AppContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from '@emailjs/browser';


function App() {

	const [theme, toggleTheme] = useDarkMode();
	// const [data, setData] = useState({})

	// const getData = async () => {
	// 	const res = await fetch('https://geolocation-db.com/json/');
	// 	console.log(res.data);
	// 	setData({
	// 		city: res.data.city,
	// 		state: res.data.state,
	// 		postal: res.data.postal,
	// 		IPv4: res.data.IPv4,
	// 		longitude: res.data.longitude,
	// 		latitude: res.data.latitude
	// 	})
	// }

	// const sendEmail = () => {
	// 	emailjs.sendForm("service_808ptqj", "template_fpxu6zn", data)
	// 		.then((result) => {
	// 			console.log(result.text);
	// 		}, (error) => {
	// 			console.log(error.text);
	// 		});
	// }
	
	// useEffect(() => {  //passing getData method to the lifecycle method   
	// 	getData()
	// 	sendEmail()
	// }, [])

	return (
		<AppContext.Provider value={{ theme, toggleTheme }}>
			<ThemeProvider theme={ theme === 'light' ? lightTheme : darkTheme }>
				<GlobalStyles />
				<div className="App">
					<Router>
						<MainRouter />
						{/* <MainRouterTest /> */}
					</Router>
				</div>
			</ThemeProvider>
		</AppContext.Provider>
  );
}

export default App;
